import React, { Component } from 'react';
import './App.css';
import logoBanner from './assets/images/logo-banner.jpg';
import ad1 from './assets/images/ad1.jpg';
import ad2 from './assets/images/ad2.jpg';
import ad3 from './assets/images/ad3.jpg';
import ad4 from './assets/images/ad4.jpg';
import ad5 from './assets/images/ad5.jpg';
import ad6 from './assets/images/ad6.jpg';
import middle from './assets/images/middle.jpg';
import footer from './assets/images/footer.jpg';
import { services } from './services/services';
import { utils } from './utils/utils';
import moment from 'moment';
import loading from './assets/images/loading.gif';

const timeStr = '13:35';
const timeAnnounce = moment().format('YYYY-MM-DD 13:25:00');
const announceStartDate = moment(timeAnnounce).format('YYYY-MM-DD HH:mm:ss');
const beforeStartUpAnnounceDate = moment(timeAnnounce).format('YYYY-MM-DD HH:mm:ss');
const afterStartUpAnnounceDate = moment(timeAnnounce).add(4.59, 'minute').format('YYYY-MM-DD HH:mm:ss');
const startUpAnnounceDate = moment(timeAnnounce).add(5, 'minute').format('YYYY-MM-DD HH:mm:ss');
const endUpAnnouneDate = moment(timeAnnounce).add(9.59, 'minute').format('YYYY-MM-DD HH:mm:ss');
const upAnnounceDate = moment(timeAnnounce).add(10, 'minute').format('YYYY-MM-DD HH:mm:ss');
const announceEndDate = moment(timeAnnounce).add(10, 'minute').format('YYYY-MM-DD HH:mm:ss');

const imageLoading = () => {
  return <img
    style={{ marginRight: 2 }}
    src={loading}
    width={30}
    height={30}
    alt='number'
  />;
}

class App extends Component {

  animationTimer = null;
  upTimer = null;

  constructor() {
    super();

    this.state = {
      periods: null,
      previousPeriod: null,
      currentPeriod: null,
      numberAnnounce: {
        number1: imageLoading(),
        number2: imageLoading(),
        number3: imageLoading(),
        number4: imageLoading(),
        number5: imageLoading(),
        number6: imageLoading()
      },
      todayDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      oldUpAnnounce: false,
      beforeAnnonce: false,
      beforeStartUp: false,
      upAnnounce: false,
      afterAnnounce: false,
      isShowing: false,

    }
  }

  componentDidMount() {
    this.getAllStatistics();
    this.initData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.animationTimer);
    clearInterval(this.upTimer);
  }

  initData = () => {
    this.animationTimer = setInterval(() => {
      const { todayDate } = this.state;
      this.setState({ todayDate: moment().format('YYYY-MM-DD HH:mm:ss') });
      if (moment(todayDate).toDate().getTime() < moment(announceStartDate).toDate().getTime()) {
        this.beforeAnnonce();
      } else if (
        moment(todayDate).toDate().getTime() >= moment(announceStartDate).toDate().getTime()
        && moment(todayDate).toDate().getTime() <= moment(announceEndDate).toDate().getTime()
      ) {
        this.intervalData();
      } else {
        this.afterAnnounce();
      }
    }, 1000);
  }

  beforeAnnonce = async () => {
    const { todayDate, beforeAnnonce } = this.state;
    if (!beforeAnnonce) {
      const dateNow = moment(todayDate).add(-1, 'd').format(`YYYY-MM-DD ${timeStr}:00`);
      const period = await services.getPeriods(dateNow, 'both', '8');
      const up = period.data.up6;
      this.setState({
        numberAnnounce: {
          number1: up[0],
          number2: up[1],
          number3: up[2],
          number4: up[3],
          number5: up[4],
          number6: up[5]
        },
        beforeAnnonce: true,
        isShowing: true
      });
    }

  }

  intervalData = async () => {
    const { todayDate, oldUpAnnounce, upAnnounce, beforeStartUp } = this.state;

    if (moment(todayDate).toDate() < moment(startUpAnnounceDate).toDate()) {
      if (!oldUpAnnounce) {
        this.setState({
          numberAnnounce: {
            number1: imageLoading(),
            number2: imageLoading(),
            number3: imageLoading(),
            number4: imageLoading(),
            number5: imageLoading(),
            number6: imageLoading()
          },
          oldUpAnnounce: true,
          isShowing: false
        });
      }
    }

    if (moment(todayDate).toDate() >= moment(beforeStartUpAnnounceDate).toDate() && moment(todayDate).toDate() <= moment(afterStartUpAnnounceDate).toDate()) {
      if (!beforeStartUp) {
        this.setState({
          numberAnnounce: {
            number1: imageLoading(),
            number2: imageLoading(),
            number3: imageLoading(),
            number4: imageLoading(),
            number5: imageLoading(),
            number6: imageLoading(),
            isShowing: false
          },
          beforeStartUp: true
        });
      }
    }

    if (moment(todayDate).toDate() >= moment(startUpAnnounceDate).toDate() && moment(todayDate).toDate() <= moment(endUpAnnouneDate).toDate()) {
      if (this.upTimer === null) {
        this.upTimer = setInterval(() => {
          this.setState({
            numberAnnounce: {
              number1: Math.floor(Math.random() * 10),
              number2: Math.floor(Math.random() * 10),
              number3: Math.floor(Math.random() * 10),
              number4: Math.floor(Math.random() * 10),
              number5: Math.floor(Math.random() * 10),
              number6: Math.floor(Math.random() * 10)
            },
            isShowing: false
          })
        }, 100);
      }
    }

    if (moment(todayDate).toDate().getTime() >= moment(upAnnounceDate).toDate().getTime()) {
      clearInterval(this.upTimer);
      if (!upAnnounce) {
        // call api and show up 
        const statisticToday = await services.getStatisticToday();
        // await this.getCurrentPeriod();
        const up = statisticToday.data.up6;

        this.setState({
          numberAnnounce: {
            number1: up[0],
            number2: up[1],
            number3: up[2],
            number4: up[3],
            number5: up[4],
            number6: up[5]
          },
          upAnnounce: true,
          isShowing: true
        })
      }
    }
  }

  afterAnnounce = async () => {
    clearInterval(this.upTimer);
    const { afterAnnounce } = this.state;
    if (!afterAnnounce) {
      const statisticToday = await services.getStatisticToday();
      const up = statisticToday.data.up6;
      this.setState({
        numberAnnounce: {
          number1: up[0],
          number2: up[1],
          number3: up[2],
          number4: up[3],
          number5: up[4],
          number6: up[5]
        },
        afterAnnounce: true,
        isShowing: true
      })
    }
  }

  getAllStatistics = async () => {
    const response = await services.getStatistics();
    this.setState({
      periods: response.data
    })
  }

  renderPeriods() {
    const { periods } = this.state;

    if (!periods) {
      return (
        <tr className="text-center">
          <td colSpan={7}>ไม่มีข้อมูล</td>
        </tr>
      );
    }

    if (periods.length === 0) {
      return (
        <tr className="text-center">
          <td colSpan={7}>ไม่มีข้อมูล</td>
        </tr>
      );
    }

    return periods.map((period, index) => {
      return (
        <tr key={index} className="text-center">
          <td>{utils.convertToThaiDate(period?.dateAnnounce.toString())}</td>
          <td style={{ verticalAlign: 'middle' }}>{period.up6}</td>
          <td style={{ verticalAlign: 'middle' }}>{period.up3}</td>
          <td style={{ verticalAlign: 'middle' }}>{period.up2}</td>
          <td style={{ verticalAlign: 'middle' }}>{period.down2}</td>
        </tr>
      );
    });
  }

  renderDateTime = () => {
    const { todayDate } = this.state;
    if (moment(todayDate).toDate().getTime() >= moment(beforeStartUpAnnounceDate).toDate().getTime()) {
      return 'ງວດປະຈຳວັນທີ່ ' + utils.convertToThaiDate(moment().toString());
    }

    return 'ງວດປະຈຳວັນທີ່ ' + utils.convertToThaiDate(moment().add(-1, 'd').toString());
  }

  renderAnnounceShowing = () => {

    const { numberAnnounce, isShowing } = this.state;
    return <>
      <div>
        <div className="content">
          <p>ເລກ 6 ໂຕ</p>
          <p className="result-digit">{isShowing ? `${numberAnnounce.number1}${numberAnnounce.number2}${numberAnnounce.number3}${numberAnnounce.number4}${numberAnnounce.number5}${numberAnnounce.number6}` : imageLoading()}</p>
        </div>
      </div>
      <div>
        <div className="content">
          <p>ເລກ 5 ໂຕ</p>
          <p className="result-digit">{isShowing ? `${numberAnnounce.number2}${numberAnnounce.number3}${numberAnnounce.number4}${numberAnnounce.number5}${numberAnnounce.number6}` : imageLoading()}</p>
        </div>
      </div>
      <div>
        <div className="content">
          <p>ເລກ 4 ໂຕ</p>
          <p className="result-digit">{isShowing ? `${numberAnnounce.number3}${numberAnnounce.number4}${numberAnnounce.number5}${numberAnnounce.number6}` : imageLoading()}</p>
        </div>
      </div>
      <div>
        <div className="content">
          <p>ເລກ 3 ໂຕ</p>
          <p className="result-digit">{isShowing ? `${numberAnnounce.number4}${numberAnnounce.number5}${numberAnnounce.number6}` : imageLoading()}</p>
        </div>
      </div>
      <div>
        <div className="content">
          <p>ເລກ 2 ໂຕລຸ່ມ</p>
          <p className="result-digit">{isShowing ? `${numberAnnounce.number1}${numberAnnounce.number2}` : imageLoading()}</p>
        </div>
      </div>
      <div>
        <div className="content">
          <p>ເລກ 2 ໂຕເທິ່ງ</p>
          <p className="result-digit">{isShowing ? `${numberAnnounce.number5}${numberAnnounce.number6}` : imageLoading()}</p>
        </div>
      </div>
    </>
  }

  render() {

    const { numberAnnounce } = this.state;

    return (
      <div className="container">
        <img src={logoBanner} className="img-fluid mb-2" width="100%" alt="laomaekhong" />
        <div className="row">
          <div className="col-md-9 col-sm-12 col-xs-12">
            <div className="inner-background">
              <div className="box">
                <div className="text-center p-2 logo-gradient">
                  <h4>{this.renderDateTime()}</h4>
                </div>
                <div className="mb-4">
                  <div className="flexbox">
                    <div className="item">
                      <div className="content-box">
                        <p className="content-number">{numberAnnounce.number1}</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="content-box">
                        <p className="content-number">{numberAnnounce.number2}</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="content-box">
                        <p className="content-number">{numberAnnounce.number3}</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="content-box">
                        <p className="content-number">{numberAnnounce.number4}</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="content-box">
                        <p className="content-number">{numberAnnounce.number5}</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="content-box">
                        <p className="content-number">{numberAnnounce.number6}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <h2>ເວລາອອກເລກ {`${timeStr}`}</h2>
                </div>
              </div>

              <div className="grid">
                {this.renderAnnounceShowing()}
              </div>
            </div>
            <div className="inner-background">
              <div className="card d-flex justify-content-center mt-0">
                <div className="card-body p-0">
                  <img src={middle} width="100%" alt="หวย หวยลาว ลาว ຫວຍແມ່ຂອງhd ຫหวยแม่โขงhd " />
                </div>
              </div>
            </div>

            <div className="stat">
              <div className="alert alert-secondary text-center header-state" role="alert">
                <h5 style={{ margin: 0 }}><b>ຜົນລາງວັນທີ່ຜ່ານມາ</b></h5>
              </div>
              <table className="table table-bordered period" style={{ marginBottom: '5px', fontSize: '16px' }}
                id="period-table">
                <thead>
                  <tr className="text-center">
                    <th style={{ verticalAlign: 'middle' }}>ງວດວັນທີ່</th>
                    <th>ເລກ 6 ໂຕ</th>
                    <th>ເລກ 3 ໂຕ</th>
                    <th>ເລກ 2 ໂຕເທິງ</th>
                    <th>ເລກ 2 ໂຕລຸ່ມ</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {this.renderPeriods()}
                </tbody>
              </table>
            </div>

            <div className="inner-background">
              <div className="card d-flex justify-content-center mt-0">
                <div className="card-body p-0">
                  <img src={footer} width="100%" alt="หวย หวยลาว ลาว ຫວຍແມ່ຂອງhd ຫหวยแม่โขงhd " />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <div className="card d-flex justify-content-center mt-0">
              <div className="card-body p-0">
                <img src={ad1} width="100%" alt="หวย หวยลาว ลาว ຫວຍແມ່ຂອງhd ຫหวยแม่โขงhd " />
              </div>
            </div>
            <div className="card d-flex justify-content-center mt-2">
              <div className="card-body p-0">
                <img src={ad2} width="100%" alt="หวย หวยลาว ลาว ຫວຍແມ່ຂອງhd ຫหวยแม่โขงhd " />
              </div>
            </div>
            <div className="card d-flex justify-content-center mt-2">
              <div className="card-body p-0">
                <img src={ad6} width="100%" alt="หวย หวยลาว ลาว ຫວຍແມ່ຂອງhd ຫหวยแม่โขงhd " />
              </div>
            </div>
            <div className="card d-flex justify-content-center mt-2">
              <div className="card-body p-0">
                <img src={ad3} width="100%" height={300} alt="หวย หวยลาว ลาว ຫວຍແມ່ຂອງhd ຫหวยแม่โขงhd " />
              </div>
            </div>
            <div className="card d-flex justify-content-center mt-2">
              <div className="card-body p-0">
                <img src={ad4} width="100%" height={350} alt="หวย หวยลาว ลาว ຫວຍແມ່ຂອງhd ຫหวยแม่โขงhd " />
              </div>
            </div>
            <div className="card d-flex justify-content-center mt-2">
              <div className="card-body p-0">
                <img src={ad5} width="100%" height={220} alt="หวย หวยลาว ลาว ຫວຍແມ່ຂອງhd ຫหวยแม่โขงhd " />
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default App;
